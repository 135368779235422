const moment = require('moment');
const slug = require('slug');

module.exports = function(dates, place, id, languageKey) {
  const subPage = {
    'en': 'gig',
    'fr': 'spectacle'
  }[languageKey];

  const normalizedPlace = place[languageKey].normalize('NFD').replace(/[\u0300-\u036f]/g, "");

  return '/' + [
    languageKey,
    subPage,
    slug([moment(dates.from).format('YYYY-MM-DD'), normalizedPlace, id.toString()].join(' ')).toLowerCase()
  ].join('/');
}
