import React from 'react'
import Link from 'gatsby-link'
import styles from './index.module.css'
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment'
import intl from 'react-intl-universal';
import { pageMap } from '../data/page-map';
import { IoIosArrowForward } from 'react-icons/io'
import eventUrl from '../event-url'

const EventRow = (props) => (
  <tr key={props.eventId}>
    <td>
      {intl.get('date', { date: moment(props.event.dates.from).toDate() })}
    </td>
    <td>
      {props.event.place[props.currentLanguage]}
    </td>
    <td>
      <a href={eventUrl(props.event.dates, props.event.place, props.event.eventId, props.currentLanguage)}>{intl.get('eventDetails')}</a>
    </td>
  </tr>
);

const Index = (props) => (
  <div className="page page-home">
    <Container>
      <Row className={styles['home-row']}>
        <Col className={styles['news-column']}>
          <h2>{intl.get('homeNews')}</h2>
          <h3>{intl.getHTML('newsHeadline')}</h3>
          <p className="date-text">
            {intl.get('date', { date: moment('2018-10-23').toDate() })}
          </p>
          {intl.getHTML('newsText')}
        </Col>
        <div className="w-100 d-md-none d-block"></div>
        <Col className={styles['gigs-column']}>
          <h2>{intl.get('homeUpcomingGigs')}</h2>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>{intl.get('eventDate')}</th>
                <th>{intl.get('eventPlace')}</th>
                <th>{intl.get('eventDetails')}</th>
              </tr>
            </thead>
            <tbody>
              {
                props
                  .events
                  .filter(x => moment(x.dates.from).isAfter(moment().subtract(1, 'day'), 'day'))
                  .sort((a, b) => moment(a.dates.from).isBefore(b.dates.from) ? -1 : 1)
                  .slice(0, 3)
                  .map(x => <EventRow key={x.eventId} event={x} currentLanguage={props.currentLanguage} />)
              }
            </tbody>
          </table>
          <Link to={`/${props.currentLanguage}/${pageMap['gigs'][props.currentLanguage]}`}>
            {intl.get('pageGigs')} <IoIosArrowForward />
          </Link>
        </Col>
      </Row>
    </Container>
  </div>
)

Index.propTypes = {
  events: PropTypes.array,
  currentLanguage: PropTypes.string
}

export default Index;
