import React from 'react'
import Index from '../../components'
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

const IndexFr = (props) => (
  <Index
    {...props}
    events={props.data.thirdPartyEvents.collection}
    currentLanguage='fr'
  />
)

IndexFr.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexFr;

export const IndexFrQuery = graphql`
  query IndexFrQuery {
    thirdPartyEvents {
      collection {
        eventId
        place {
          en
          fr
        }
        dates {
          from
          to
        }
        description {
          en
          fr
        }
      }
    }
  }
`
